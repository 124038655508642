<template>
  <v-img
    :src="require('@/assets/logo.svg')"
    class="my-3"
    contain
    height="18"
    width="123"
  />
</template>

<script>
export default {
  name: "HeaderLogo",
};
</script>

