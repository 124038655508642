<template>
  <div>
    <div v-if="!file">
      <div
        :class="['dropZone', dragging ? 'dropZone-over' : '']"
        @dragenter="dragging = true"
        @dragleave="dragging = false"
      >
        <div class="title">Attachments*</div>
        <div class="dropZone-info" @drag="onChange">
          <div class="row justify-center" style="margin: 0 !important">
            <span class="dropZone-title"
              ><v-img
                width="15px"
                height="13.75px"
                class="mt-1"
                src="@/assets/ticket/file-upload.svg"
            /></span>
            <span class="dropZone-title ml-2"
              >Select or drag a file from computer</span
            >
          </div>
          <!-- <span class="dropZone-title"><v-icon>mdi-folder-upload</v-icon></span> -->
          <div class="dropZone-upload-limit-info">
            <div class="file-type">
              <!-- DOC,DOCX ,XLSX-->
              (Supported Files: PNG,JPG,JPEG,PDF)
            </div>
          </div>
        </div>
        <input
          type="file"
          @change="onChange"
          multiple
          accept="image/jpg, image/png, image/jpeg, application/pdf"
        />
      </div>
    </div>
    <div v-else class="dropZone-uploaded">
      <div class="dropZone-uploaded-info">
        <span class="dropZone-title" v-if="file.name"
          >{{ file.name | minimizeFileName }} ({{
            (file.size / (1024 * 1024)).toFixed(2)
          }}mb)</span
        >
        <button
          type="button"
          class="btn btn-primary removeFile"
          @click="removeFile"
        >
          Remove File
        </button>
      </div>
    </div>

    <!-- <div class="uploadedFile-info">
      <div>fileName: {{ file.name }}</div>
      <div>fileZise(bytes): {{ file.size }}</div>
      <div>extension：{{ extension }}</div>
    </div> -->
  </div>
</template>
<script>
import { PDFDocument } from "pdf-lib";
export default {
  data() {
    return {
      file: "",
      dragging: false,
    };
  },
  methods: {
    async onChange(e) {
      var files = e.target.files || e.dataTransfer.files;

      if (!files.length) {
        this.dragging = false;
        return;
      }
      /*
       ** Add feature for multiple types and its files
       */
      const mergedFile = await this.convertToPdf(files);
      if (!mergedFile)
        this.$showNotificationBar(
          "error",
          "Only multiple PDF's and Images can be selected"
        );
      this.createFile(mergedFile);
    },
    createFile(file) {
      if (
        !file.type.match(".png") &&
        !file.type.match(".jpg") &&
        !file.type.match(".jpeg") &&
        !file.type.match(".doc") &&
        !file.type.match(".docx") &&
        !file.type.match(".pdf") &&
        !file.type.match(".xlsx")
      ) {
        this.$showNotificationBar(
          "error",
          // "Supported files: png,jpg,jpeg,doc,docx,pdf,xlsx"
          "File type is not supported"
        );
        this.dragging = false;
        return;
      }

      if (file.size >= 5242880) {
        this.$showNotificationBar(
          "error",
          "File Size should be less than 5 mb."
        );
        this.dragging = false;
        return;
      }

      this.file = file;
      this.dragging = false;
      this.$emit("file-change", this.file);
    },
    removeFile() {
      this.file = "";
      this.$emit("file-change", this.file);
    },
    /*
     ** Modify current flow for the multiple pdf files
     */
    async mergePDFs(uint8Arrays) {
      // Create a new PDF document
      const mergedDocument = await PDFDocument.create();

      // Iterate over each input Uint8Array
      for (const uint8Array of uint8Arrays) {
        // Load the PDF document from the Uint8Array
        const pdfDoc = await PDFDocument.load(uint8Array);

        // Get the page count of the loaded document
        const pageCount = pdfDoc.getPageCount();

        // Iterate over each page in the loaded document
        for (let i = 0; i < pageCount; i++) {
          // Copy the page from the loaded document to the merged document
          const [copiedPage] = await mergedDocument.copyPages(pdfDoc, [i]);
          mergedDocument.addPage(copiedPage);
        }
      }

      // Save the merged document as Uint8Array
      const mergedBytes = await mergedDocument.save();
      return mergedBytes;
    },

    // Function to check the file extension and return the corresponding type
    checkFileExtension(filePath) {
      const extension = filePath.name.split(".").pop().toLowerCase();
      if (extension === "pdf") {
        return "PDF";
      } else if (extension === "jpg" || extension === "jpeg") {
        return "JPG";
      } else if (extension === "png") {
        return "PNG";
      } else {
        return "Unknown";
      }
    },

    // Function to validate the files
    validateFiles(files) {
      if (files.length === 1) return "0"; // Single file
      else if (files.length > 1) {
        for (let i = 0; i < files.length; i++) {
          const response = this.checkFileExtension(files[i]);
          if (response === "Unknown") {
            return "1"; // Files with unknown extension
          }
        }
        return "2"; // Multiple files with valid extensions
      }
    },

    // Function to convert files to PDF
    async convertToPdf(files) {
      // Validate the files
      const validationResponse = this.validateFiles(files);

      if (validationResponse == "0") {
        return files[0]; // Single PDF file, return as is
      } else if (validationResponse == "1") {
        return ""; // Files with unknown extension, return empty string
      }

      // Array to store converted files as PDF Uint8Arrays
      const mergedArrayAsPDF = [];

      // Iterate over each file
      for (let i = 0; i < Object.keys(files).length; i++) {
        // Check the file type
        if (files[i].type == "application/pdf") {
          const buff = await files[i].arrayBuffer();
          const pdfBytes = new Uint8Array(buff);
          mergedArrayAsPDF.push(pdfBytes); // Push PDF file as Uint8Array
        } else if (
          files[i].type == "image/png" ||
          files[i].type == "image/jpeg" ||
          files[i].type == "image/jpg"
        ) {
          const pdfBytes2 = await this.convertImageToPdf(files[i]);
          mergedArrayAsPDF.push(pdfBytes2); // Push converted image file as Uint8Array
        }
      }

      // Merge the PDFs
      const onePdf = await this.mergePDFs(mergedArrayAsPDF);

      // Create a Blob from the merged PDF Uint8Array
      const blob = new Blob([onePdf], {
        type: "application/pdf",
      });

      // Create a merged file from the Blob
      const mergedFile = new File([blob], `Merged-File.pdf`, {
        type: "application/pdf",
      });

      return mergedFile;
    },

    // Function to convert an image file to PDF
    async convertImageToPdf(file) {
      try {
        // Create a new PDF document
        const pdfDoc = await PDFDocument.create();

        // Check the file type and return the embedded image
        const image = await this.checkFileTypeAndReturnImg(file, pdfDoc);

        // Get the image dimensions
        const dimension = image.scale(1);

        // Add a new page to the PDF document with the same dimensions as the image
        const page = pdfDoc.addPage();
        page.setSize(dimension.width, dimension.height);

        // Draw the image on the page
        page.drawImage(image, {
          x: 0,
          y: 0,
          width: dimension.width,
          height: dimension.height,
        });

        // Save the PDF document as Uint8Array
        const pdfBytes = await pdfDoc.save();
        return pdfBytes;
      } catch (error) {
        console.log("error here", error);
        this.$showNotificationBar("error", error);
        this.file = "";
      }
    },

    // Function to check the file type and return the embedded image
    async checkFileTypeAndReturnImg(file, pdfDoc) {
      let image;
      const buffer = await file.arrayBuffer();
      if (file.type == "image/png") {
        image = await pdfDoc.embedPng(buffer);
      } else if (file.type == "image/jpg" || file.type == "image/jpeg") {
        image = await pdfDoc.embedJpg(buffer);
      }
      return image;
    },
  },
  computed: {
    extension() {
      return this.file ? this.file.name.split(".").pop() : "";
    },
  },
};
</script>
<style scoped lang="scss">
.dropZone {
  width: 100%;
  height: 100px;
  position: relative;
  // border: 2px dashed #eee;
}

// .dropZone:hover {
// border: 2px solid #2e94c4;
// }

.dropZone .dropZone-title {
  color: #6634e9;
}
.dropZone:hover .dropZone-title {
  color: #6634e9;
}

.dropZone-info {
  color: #a8a8a8;
  position: absolute;
  top: 60%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.dropZone-title {
  color: #6634e9;
}

.dropZone input {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.dropZone-upload-limit-info {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 6px;
}

.dropZone-over {
  background: #5c5c5c;
  opacity: 0.8;
}

.dropZone-uploaded {
  width: 100%;
  height: 100px;
  position: relative;
  // border: 2px dashed #eee;
}

.dropZone-uploaded-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #a8a8a8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.removeFile {
  width: 100px;
}
.file-type {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #9d9eab;
  text-align: center;
}
.title {
  font-style: normal;
  font-weight: 500;
  font-size: 12px !important;
  line-height: 16px;
  color: #40415a;
  padding-top: 13px;
  padding-left: 16.5px;
}
</style>
